import React from 'react'
import { motion } from 'framer-motion'
import './home.scss';
export default function Home() {
  const headVariants = {
    initial: { x: -500, opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { duration: 1.5, delay: 0.5, damping: 4, straggerChildren: 0.6 } }
  }
  const sideVariants = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1, transition: { duration: 1.5, delay: 1, damping: 4, straggerChildren: 0.6 } }
  }
  return (
    <div className='container'>
      <motion.div className="home-grid-one" variants={headVariants} initial='initial' animate='animate'>
        <motion.div className="heading-container" variants={headVariants}>
          <motion.h1 className="home-head-one" variants={headVariants}>Empowering Tomorrow's Innovation </motion.h1>
          <motion.div variants={headVariants}>
            <motion.h1 className="home-head-two" variants={headVariants}>- XTENSU SOFTTECH SOLUTIONS </motion.h1>
          </motion.div>
          <motion.div variants={headVariants}>
            <motion.div variants={headVariants}>
              <motion.h1 className="home-head-three" variants={headVariants}> "Your Gateway to Cutting-Edge IT Excellence."</motion.h1>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div className="home-grid-two" variants={sideVariants} initial="initial" animate="animate">
        <motion.div className="home-contact-statement" variants={sideVariants}>
          
          <motion.a href="#Contact" className="home-contact-anchor" variants={sideVariants}>
            <motion.button initial={{ scale: 0.9 }} whileHover={{ scale: 1 ,transition:{duration:0.5}}} className="enquiry">Get In Touch</motion.button>
          </motion.a>
        </motion.div>

      </motion.div>

    </div>
  )
}
