import React from 'react'
import './products.scss'
import {motion } from 'framer-motion';


export default function Porducts() {


  // const headVariants = {
  //   initial: { y: -80, opacity: 0 },
  //   animate: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.2 , } }
  // }
  
  const headVariants = {
    initial: { y: 80, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.2 , } }
  }
  const productVariants = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.5,} }
  }
  
  return (
    <div className='product-container'>
      <motion.div className="title" variants={headVariants} initial='initial' whileInView="animate" >
        <motion.h1 className="product-head" variants={headVariants}>Our Products</motion.h1>

      </motion.div>
      <motion.div className="image-container" variants={productVariants} initial='initial' whileInView="animate">
        <motion.a href="https://tooheal.com/" target='_blank' rel="noreferrer" className="link" variants={productVariants} whileHover={{ scale: 1.04, transition: { duration: 0.5, damping: 1} }}><img src="/tooHeal.png" alt="Error" className="tooheal" />
          <motion.h3 className='visit-link' variants={productVariants} whileHover={{ scale: 1.1, transition: { duration: 2 } }}>   <i class="fa-solid fa-arrow-up-right-from-square" style={{ color: "#454545;" }}></i> </motion.h3>
        </motion.a>
        <motion.h3 className="product-name" variants={productVariants}>TooHeal</motion.h3>
        <motion.p className="product-para" variants={productVariants}>An innovative solution, where we are available to listen all your problems and worries through 1:1 sessions with peers of your own age group.</motion.p>
      </motion.div>
      <motion.div className='scroll-container'><motion.img src='/scroll.png' alt='Error' className='scroll' initial={{y:-50,opacity:0}} animate={{y:-20,opacity:1,transition:{duration:3 ,repeat:Infinity}}}></motion.img></motion.div>
      
    </div>
  )
}
