import React from 'react'
import './footer.scss'

export default function Footer() {
    return (
        <div className='footer-container'>
            <div className="secondary-container">
                <div className='footer-logo-container'>
                    <img src="/LogoPlain2.png" alt="Error" className="footer-logo" />
                </div>
                <div className="quick-links">
                    <h3 className='quick-head'>Quick Links</h3>
                    <ul>
                        <li><a href='#Home'>Home</a></li>
                        <li><a href='#About'>About</a></li>
                        <li><a href='#Products'>Our Products</a></li>
                        <li><a href='#Products'>T&C</a></li>
                        <li><a href='#Products'>Privacy policy</a></li>

                    </ul>
                </div>
                <div className="footer-contact">
                    <h3 className='quick-head'>Contact Us</h3>
                    <p>Drop your queries here and we will get back to you ASAP with your answers.</p>
                    <button className="contact-footer"><a href='#Contact'>Contact Us</a></button>
                </div>
            </div>
            <div className="reserved">
                <p className="reserved-text">&#169; XTENSU SOFTTECH SOLUTIONS PRIVATE LIMITED
                    <br></br>2023 All Right Reserved.</p>
            </div>

        </div>
    )
}
