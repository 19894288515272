import './App.scss'
import{motion} from 'framer-motion'
import About from './components/AboutUs/About';
import Contact from './components/ContactUs/Contact';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Porducts from './components/Ourproduts/Porducts';
import Footer from './components/footer/Footer';
import logo from './logo.svg';


function App() {
  const variants ={
    initial:{y:-200,opacity:0},
    animate:{y:0,opacity:1,transition:{duration:0.8,delay:0.5,damping:5}}
  }
 
  return (
    <div className="app">
      <div>
        <Navbar />

      </div>
      <motion.div id='Home' variants={variants} initial="initial" animate="animate">
        <Home />

      </motion.div>
      <div id='About'>
        <About />

      </div>
      <div id='Products'>
        <Porducts />

      </div>
      <div id='Contact'>
        <Contact />

      </div>
      <div>
        <Footer />

      </div>
    </div>
  );
}

export default App;
