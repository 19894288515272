import React from 'react';
import './about.scss';
import {motion} from 'framer-motion'
export default function About() {
    const headVariants ={
        initial:{y:-100,opacity:0, scale:0},
        animate:{y:0,opacity:1, scale:1, transition:{duration:1.8,delay:1.5,damping:5, straggerChildren:0.5}}
      }
    return (
        <motion.div className='about-container' variants={headVariants} initial='initial' animate='animate'>
            <div className="text-container">
                <h1 className="about-head roboto-bold">About Us</h1>
                <div className="grid-container">
                    <div className="gridOne">
                        <p className="text">
                            Xtensu SoftTech Solutions Private Limited is a dynamic IT company committed to delivering innovative technology solutions that empower businesses to thrive in the digital age. With a relentless passion for excellence and a customer-centric approach, we bring cutting-edge technology within your reach. With a focus on innovation and well-being, we have developed a cutting-edge product known as the "TooHeal" to support individuals and organizations in promoting emotional  well-being.
                        </p>
                    </div>
                    <div className="gridTwo">
                        <img src="/work.svg" alt="Error" className="about-image" />
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
