import React, { useState } from 'react'
import { easeInOut, motion } from 'framer-motion'
import './nav.scss'

export default function Navbar() {

  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  }

  const variants = {
    initial: { y: -100, scale: 1, opacity: 0 },
    animate: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.7, straggerChildren: 0.6 } }
  }

  const navOpen = {
    closed: {
      clipPath: "rectangle(100px at 0px 0px)",
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 30,
        duration: 3.1,
      },
    },

    open: {
      clipPath: "circle(1200px at 50px 50px)",
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 3,

      }

    },
    opacity: 0
  }

  return (
    <motion.div className='navbar' variants={variants} initial='initial' animate='animate'>

      <motion.div className="nav-logo" variants={variants} >
        <motion.a href='#Home'><motion.img src="/LogoPlain2.png" alt="" className="logo-image" /></motion.a>
        <button onClick={handleToggle} className='nav-toggle-button'>{toggle ? <img src='/cross.svg' alt='Error' className='cross' /> : <img src='/hamburger.svg' alt='Error' className='hamburger' />}</button>
      </motion.div>

      <motion.div className={toggle ? "nav-links show-navbar-collapse" : "nav-links"} animate={toggle ? "open" : "closed"} variants={navOpen} whileFocus={{ backgroundColor: 'blue' }}>
        <motion.ul variants={variants} >
          <motion.li variants={variants} whileHover={{ color: 'blue', scale: 1.2, transition: 'all 300ms easeInOut' }}><a href='#Home'>Home</a></motion.li>
          <motion.li variants={variants} whileHover={{ color: 'blue', scale: 1.2 }}><a href='#About'>About</a></motion.li>
          <motion.li variants={variants} whileHover={{ color: 'blue', scale: 1.2 }}><a href='#Products'>Our Products</a></motion.li>
          <motion.li variants={variants} whileHover={{ color: 'blue', scale: 1.2 }}><a href='#Contact'>Contact Us</a></motion.li>
          

        </motion.ul>

      </motion.div>
    </motion.div >
  )
}
